<template>
  <div class="container">
    <iframe
      class="child"
      src="https://h5.ultranaira.com/#/termsCondition"
      frameborder="0"
      :style="{width: width, height:height}"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      height: (window.innerHeight) + 'px',
      width: '100%'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
